@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap');

:root {
    --maincolor: #003696;
    --secondcolor: #F5F5F5;
    --thirdcolor: #E63946;
    --forthcolor: #D8F0FF;
    --fifthcolor: #285ABC;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}


.nietgevonden{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    width: 100vw;
}

.btnnietgevonden{
    background-color: var(--maincolor);
    border-radius: 50px;
    color: white !important;
    padding: 1em 15em;
    margin-top:2vh;
    font-weight: 700;
}

header {
  height: 20vh;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 7vw;
  padding-right: 5vw;
  height: 100%;

}

.logo img {
  height: 5em;
}

a{
  text-decoration: none !important;
  color: black !important;
  font-weight: 700 !important;
}

.lds-ring {

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--thirdcolor) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

.commingsoon{
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation : ani  ease-in-out 1s;

}
.commingsoon img{
  width: 128px;
}

@keyframes ani {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.dots{
  margin-top: 20px;
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--maincolor);
  color: var(--secondcolor);
  box-shadow: 9984px 0 0 0 var(--maincolor), 9999px 0 0 0 var(--maincolor), 10014px 0 0 0 var(--maincolor);
  animation: dotTyping 1.5s infinite linear;
}
@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 var(--maincolor), 9999px 0 0 0 var(--maincolor), 10014px 0 0 0 var(--maincolor);
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 var(--maincolor), 9999px 0 0 0 var(--maincolor), 10014px 0 0 0 var(--maincolor);
  }
  33.333% {
    box-shadow: 9984px 0 0 0 var(--maincolor), 9999px 0 0 0 var(--maincolor), 10014px 0 0 0 var(--maincolor);
  }
  50% {
    box-shadow: 9984px 0 0 0 var(--maincolor), 9999px -10px 0 0 var(--maincolor), 10014px 0 0 0 var(--maincolor);
  }
  66.667% {
    box-shadow: 9984px 0 0 0 var(--maincolor), 9999px 0 0 0 var(--maincolor), 10014px 0 0 0 var(--maincolor);
  }
  83.333% {
    box-shadow: 9984px 0 0 0 var(--maincolor), 9999px 0 0 0 var(--maincolor), 10014px -10px 0 0 var(--maincolor);
  }
  100% {
    box-shadow: 9984px 0 0 0 var(--maincolor), 9999px 0 0 0 var(--maincolor), 10014px 0 0 0 var(--maincolor);
  }
}

@keyframes lds-ring {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}



@media (max-width: 1350px) {
  .logo img {
      height: 3em;
  }
  header {
    height: 15vh;

  }
  .nietgevonden{
    height: 70vh;
    padding: 0 5vw;
    
  }
  .btnnietgevonden{
    padding: 1em 10em;
  }

}