@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap');

:root {
    --maincolor: #003696;
    --secondcolor: #F5F5F5;
    --thirdcolor: #E63946;
    --forthcolor: #D8F0FF;
    --fifthcolor: #285ABC;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}



.rightnav {
    display: flex;
    align-items: center;

}

.tabnav {
    font-weight: 700;
    margin: 20px;
}

.butnav {
    background-color: var(--maincolor);
    border-radius: 50px;
    color: white;
    padding: 0.4em 1.5em;
}

.eventmain {
    padding-top: 5vh;
    height: fit-content;
}

h3 {
    font-weight: 700;
    font-size: 1.5em;
    margin-left: 5px;

}
h4{
    margin-bottom: 0;
    margin-left:  -2px;
}

.weekwrapper {
    display: flex;
    height: fit-content;
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.day {
    margin: 5px;
    color: #0037965f;
    font-weight: 700;
    display: flex;
}

.leftdays {
    width: 20%;
    animation: fadeInAnimationRight ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    top: 50vh;
}

.leftdays .weekdagenwrap div {
    margin: 5px;
    color: #0037965f;
    font-weight: 700;
    display: flex;

    align-items: center;
    transition: 0.5s;


}
.weekdag{
    display: flex;
    flex-direction: row;
    transition: 0.5s;
    margin : 5px;
}
.filter{
    display: flex;
    flex-direction: row;
    margin : 5px;
    align-items: center;
}
.filternaam{
    margin-left: 0.5em;
    font-weight: 700;
    font-size: 0.9em;

}
.weekdag:hover {
    cursor: pointer;
}

.weekdag:hover div {
    color: var(--maincolor);

}

.weekdag:hover .boll {

    background-color: var(--maincolor);
    border: 2px solid var(--maincolor);

}

.gevuld {
    background-color: var(--maincolor);
    border: 2px solid var(--maincolor);
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.boll {

    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: white;
    border: 2px solid var(--maincolor);
}

.check {
    color: var(--maincolor) !important;
}

.check .boll {
    background-color: var(--maincolor);
}

.categoryFilterSelect{
    border : 3px solid var(--maincolor);
    border-radius: 50px;
    padding: 0.2em 1em;
    width: 60%;
    font-weight: 700;
    font-size: 1em;
    text-align: center;
    color:white;
    background-color: var(--maincolor);
}
.categoryFilterSelect:hover{
    cursor: pointer;
}
.categoryFilterSelect:focus{
    outline: none;
}
.categoryFilterSelect option{
    color: black;
    background-color: white;
    font-weight: 400;
    border-radius: 0 !important;
    font-size: 1em;
    text-align: start;
    padding: 0.2em 1em;
    
}
.categoryFilter{
    display: flex;
    flex-direction: column;

}
.categoryFilterTitle{
    margin-top:  1em;
    font-weight: 700;
    margin-bottom: 0.5em;
    margin-left: 0.5em;
    color : var(--maincolor);
}
.checkb{
    
   
}
.categoryFilterButtons{
    color : #0037965f;
    font-weight: 700 !important;
    font-size: 1.2em;
    margin: 5px;
    display: flex;
    flex-direction: column;
}

.rightevents {
    transition: 2s cubic-bezier(.68, -0.4, .27, 1.34) .2s;
    width: 80%;
    
    display: grid;
    grid-template-columns: auto auto auto;
    min-height: 350px;
    height: fit-content;
    place-items: center;
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    position: relative;
    margin-right: 5vw;
    grid-row-gap: 5em;
}

.righteventsclicked {
    right: 0vh;
    color: white !important;
    opacity: 0;

}

.event {
    width: 300px;
    min-height: 100px;
    height: fit-content;
    background: #FFFFFF;
    border: 3px solid #003696;
    box-shadow: 14px 14px 0px #285ABC;
    position: relative;
    transition: 1s;
}

#clickeddag {
    position: absolute;
    left: 10vw;
    top: 30vh;
    font-size: 1.5em;

}

.event h4 {
    font-weight: 700;
    padding-left: 1em;
    padding-top: 1em;
    font-size: 1.1em;
}

.but {
    padding: 0.1em 0.2em;
    position: absolute;
    bottom: 10px;
    right: 1.2em;
    background-color: var(--thirdcolor);
    width: 40px;
    text-align: center;
    color: white;
    border-radius: 50px;
    font-size: 0.7em;
    transition: 1s;
}

.place {
    padding-left: 1em;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.but:hover {
    width: 100px;
    cursor: pointer;

}

.event:hover {
    cursor: pointer;

}


@keyframes fadeInAnimation {
    0% {
        opacity: 0;
        transform: translateY(4rem);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInAnimationRight {
    0% {
        opacity: 0;
        transform: translateX(-4rem);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.toon {
    display: block;
}

.toonniet {
    display: none;
}

a {
    text-decoration: none;
    color: black;
}

.infoevent{
    height: 100px;
    display: flex;
    justify-content: space-between;
    padding-right: 1em ;
}
.infoeventright{
    padding-top:  1em;
}
.time{
    color : var(--thirdcolor);
    font-weight: 700;
    display: flex;
    align-items: center;
}
.iconEvent{
    margin-right: 0.2em ;
    color : var(--thirdcolor);
}


.imgholder{
    position: relative;
    height: 150px;
}
.imgholder img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.categories{
    position: absolute;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    bottom: 0.5em;
    left: 0.5em;

}
.category{
    background-color: var(--thirdcolor);
    color: white;
    padding: 0.3em 1em;
    border-radius: 50px;
    font-weight: 700;
    font-size: 0.8em;
    margin: 0.2em;
    transition: 0.5s;
}


@media (max-width: 1350px) {
    .but{
        padding: 0.5em 0.2em;
        position: absolute;
        bottom: 10px;
        left:auto;
        right: 1.2em;
        background-color: var(--thirdcolor);
        width: 40px;
        text-align: center;
        color: white;
        border-radius: 50px;
        font-size: 0.7em;
        transition: 1s;
        
    }
    .but:hover {
        width: 40px;
        cursor: pointer;
    
    }
    .rightnav {
        display: none;
    }
    body{
    
    }
    .eventmain {
        padding-top: 3vh;
        padding-left: 2vw;
        padding-right: 2vw;
        height: fit-content;
    }

    .eventmain h1 {
        text-align: center;
        font-size: 2em;
    }
    footer{
        height: 5vh;
        width: 100%;
    }
    .categoryFilter{
        padding-left : 2em;
        padding-right : 2em;
    }
    .categoryFilterSelect{
        padding : 1em 1em;
        width: fit-content;
    }
    .categoryFilterTitle{
        display: none;
    }
    .weekwrapper{
        margin-top: 0;
        position: relative;
    }
    .leftdays {
        width: 100%;
        
        position: absolute;
        top: 7vh;
    }   
  
    .weekdagenwrap{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
    
    .leftdays .weekdagenwrap div {
        margin: 5px;
        flex-direction: column;
        align-items:flex-start;
    }
    .weekdag div:last-child {
        font-family: monospace;
        width: 2ch;
        font-size: 1.5em;
        overflow: hidden;
        white-space: nowrap;
        text-align: start;
    }
    
    #clickeddag {
        position: absolute;
        left: 8vw;
        top: 18vh;
        font-size: 1.5em;
      
    
    }
    
    .rightevents {
        width: 100%;
        display: grid;
        grid-template-columns: auto;
        min-height: 20vh;
        height: fit-content;
        grid-row-gap: 2em;
        place-items:center;
        padding-bottom: 10vh;
        position: relative;
        margin-top: 60%;
    }
    .event {
        width: 90%;
   
        background: #FFFFFF;
  
    }
    h3 {
        font-weight: 700;
        font-size: 1.5em;
        margin-left: 7vw;
        
    }

}